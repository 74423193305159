exports.items={
    "Appetizer":[
    {
            name:"Egg Roll-Pork or Vegetable (2)",
            price:2.50
        },
    {
            name:"Egg Roll-Chicken (2)",
            price:3.95
        },
    {
            name:"Cold Spring Roll-Shrimp (2)",
            price:3.95
        },
    {
            name:"Fried Spring Roll (5)",
            price:3.95
        },
    {
            name:"Fried Pork Wonton (6)",
            price:3.95
        },
    {
            name:"Edamame",
            price:3.95
        },
    {
            name:"Spicy Edamame*",
            price:4.95
        },
    {
            name:"Crab Puff (6)",
            price:4.95
        },
    {
            name:"BBQ Ribs",
            price:5.25
        },
    {
            name:"Sliced BBQ Pork",
            price:5.25
        },
    {
            name:"Fried Dumplings-Pork or Vegetable (6)",
            price:5.95
        },
    {
            name:"Steam Dumplings-Pork or Vegetable (6)",
            price:5.95
        },
    {
            name:"Chicken Lettuce Wrap",
            price:6.95
        },
    {
            name:"Fried Chicken Wings (6)",
            price:7.95
        }
    ],
    "Rice & Noodles":[
    {
            name:"Fried Rice (Chicken, Shrimp, Beef, Pork, or House)",
            price:9.99
        },
    {
            name:"Fried Rice (Vegetable)",
            price:8.99
        },
    {
            name:"Lo Mein (Chicken or Vegetable)",
            price:8.25
        },
    {
            name:"Lo Mein (Shrimp, Beef, or Pork)",
            price:8.50
        },
    {
            name:"Lo Mein (House)",
            price:8.95
        },
    {
            name:"Mai Fun (Chicken, Vegetable, Shrimp, Beef, Pork, or House)",
            price:9.99
        },
    {
            name:"Flat Noodle (Chicken, Vegetable, Shrimp, Beef, Pork, or House)",
            price:9.99
        },
    {
            name:"Singapore Style Fried Vermicelli*",
            price:9.99
        },
    {
            name:"Fried Flat Rice Noodle with Beef",
            price:9.99
        },
    {
            name:"Flat Rice Noodle with Beef and Chinese Broccoli (brown sauce)",
            price:9.99
        },
    {
            name:"Beef & Chinese Broccoli Pan-Fried Noodles",
            price:10.99
        },
    {
            name:"Salty Fish & Chicken Fried Rice",
            price:11.99
        },
    {
            name:"Seafood Stir-Fried Flat Rice Noodle",
            price:12.99
        },
    {
            name:"House Special Pan-Fried Noodles ",
            price:12.99
        }
    ],
    "Soup":[
    {
            name:"Egg Drop soup ",
            price:"(S)2.10 (L)3.50 (Family)8.95"
        },
    {
            name:"Hot and Sour soup*",
            price:"(S)2.25 (L)3.95 (Family)8.95"
        },
    {
            name:"Wonton soup",
            price:"(S)2.25 (L)3.95 (Family)8.95"
        },
    {
            name:"Vegetable soup",
            price:5.95
        },
    {
            name:"House Special Wonton soup",
            price:7.95
        },
    {
            name:"House Special Noodle soup",
            price:7.95
        },
    {
            name:"Seafood soup",
            price:7.95
        },
    {
            name:"Creamed Corn and Chicken soup",
            price:10.99
        },
    {
            name:"Minced Beef and Egg White soup",
            price:10.99
        },
    {
            name:"Fish and Egg White with Mushroom soup",
            price:10.99
        },
    {
            name:"Crab Meat and Asparagus soup",
            price:12.99
        },
    {
            name:"Crab Meat and Fish Maw soup",
            price:13.99
        },
    
    ],
    "Duck & Chicken":[
    {
            name:"Sweet and Sour Chicken",
            price:9.25
        },
    {
            name:"Chicken with Broccoli",
            price:9.25
        },
    {
            name:"Chicken with Mixed Vegetables",
            price:9.25
        },
    {
            name:"Mongolian Chicken*",
            price:9.25
        },
    {
            name:"Hunan Chicken*",
            price:9.25
        },
    {
            name:"Moo Shu Chicken",
            price:9.25
        },
    {
            name:"Moo Goo Gai Pan",
            price:9.50
        },
    {
            name:"Chicken with String Beans ",
            price:9.50
        },
    {
            name:"Chicken with Curry Sauce*",
            price:9.50
        },
    {
            name:"Chicken with Garlic Sauce*",
            price:9.95
        },
    {
            name:"Kung Pao Chicken*",
            price:9.99
        },
    {
            name:"Crispy Spicy Salt Chicken*",
            price:9.99
        },
    {
            name:"General Tso’s Chicken*",
            price:10.95
        },
    {
            name:"Sesame Chicken",
            price:10.95
        },
    {
            name:"Chicken with Cashew Nuts",
            price:10.99
        },
    {
            name:"Orange Chicken*",
            price:11.95
        },
    {
            name:"Peking Duck",
            price:39.99
        }
    ],
    "Beef & Pork":[
    {
            name:"Beef with Snow Pea and Mushroom",
            price:12.99
        },
    {
            name:"Hunan Beef*",
            price:12.99
        },
    {
            name:"Kung Pao Beef*",
            price:12.99
        },
    {
            name:"Pepper Steak",
            price:12.99
        },
    {
            name:"Beef with Scallions*",
            price:12.99
        },
    {
            name:"Beef with Chinese Broccoli",
            price:12.99
        },
    {
            name:"Beef Broccoli",
            price:12.99
        },
    {
            name:"Beef with Pickled Vegetables",
            price:12.99
        },
    {
            name:"Beef with Peking Sauce",
            price:13.99
        },
    {
            name:"Spicy Salty Baby Beef Ribs*",
            price:12.99
        },
    {
            name:"Pork with Garlic Sauce*",
            price:8.99
        },
    {
            name:"Sweet and Sour Pork",
            price:9.25
        },
    {
            name:"Pork with Garlic Sauce*",
            price:9.25
        },
    {
            name:"Pork with Orange Sauce*",
            price:11.99
        },
    {
            name:"Pork with Peking Sauce",
            price:11.99
        },
    {
            name:"Hong Kong Style Sweet and Sour Pork",
            price:10.99
        },
    {
            name:"Pork Intestine with Pickled Vegetable ",
            price:13.99
        },
    {
            name:"Deep Fried Crispy Salty Pork Intestine",
            price:13.99
        }
    ],
    "Vegetable":[
    {
            name:"Mixed Vegetable Delight",
            price:8.95
        },
    {
            name:"String Bean with Spicy Sauce*",
            price:8.95
        },
    {
            name:"String Bean with Minced Pork",
            price:8.95
        },
    {
            name:"Kung Pao Bean Curd*",
            price:8.95
        },
    {
            name:"Moo Shu Vegetables",
            price:8.95
        },
    {
            name:"Broccoli with Garlic Sauce*",
            price:9.50
        },
    {
            name:"Chinese Broccoli with Oyster sauce",
            price:9.50
        },
    {
            name:"Eggplant with Garlic Sauce*",
            price:9.50
        },
    {
            name:"Snow Peas and Water Chestnut with Garlic",
            price:9.50
        }
    ],
    "Sizzling Plate":[
    {
            name:"Chicken with Black Bean Sauce",
            price:11.99
        },
    {
            name:"Beef with Black Pepper Sauce",
            price:13.99
        },
    {
            name:"Baby Beef Ribs with Black Pepper Sauce",
            price:13.99
        },
    {
            name:"Seafood with Black Bean Sauce ",
            price:14.99
        }
    ],
    "Clay Pot":[
    {
            name:"Mixed Seafood and Fried Tofu with XO sauce",
            price:13.99
        },
    {
            name:"Baby Beef Ribs with Eggplant ",
            price:13.99
        },
    {
            name:"Salty Fish Chicken and Eggplant ",
            price:13.99
        },
    {
            name:"Salty Fish Chicken and Tofu ",
            price:13.99
        },
    {
            name:"Beef Stew",
            price:13.99
        }
    ],
    "Seafood":[
    {
            name:"Sweet and Sour Shrimp",
            price:11.95
        },
    {
            name:"Shrimp with Broccoli",
            price:11.95
        },
    {
            name:"Shrimp with Vegetables",
            price:11.95
        },
    {
            name:"Hunan Shrimp*",
            price:11.95
        },
    {
            name:"Kung Pao Shrimp*",
            price:11.95
        },
    {
            name:"Shrimp with Lobster Sauce",
            price:11.95
        },
    {
            name:"Shrimp with Garlic Sauce*",
            price:11.99
        },
    {
            name:"Shrimp with Scrambled Eggs ",
            price:12.99
        },
    {
            name:"Shrimp with Honey Walnut in Mayo Sauce",
            price:14.99
        },
    {
            name:"Shrimp with Vegetables and Roasted Walnuts",
            price:14.99
        },
    {
            name:"Shrimp with Cashew Nuts ",
            price:14.99
        },
    {
            name:"Shrimp with Soft Tofu",
            price:12.75
        },
    {
            name:"Fish Fillet with Soft Tofu",
            price:12.45
        },
    {
            name:"Spicy Salty Shrimp*",
            price:12.99
        },
    {
            name:"Spicy Salty Toasted Squid*",
            price:12.99
        },
    {
            name:"Spicy Salty Fish Fillet*",
            price:12.99
        },
    {
            name:"Fresh Squid with Black Bean Sauce",
            price:12.99
        },
    {
            name:"Fish Fillet with Black Bean Sauce",
            price:12.99
        },
    {
            name:"Fresh Squid with Pickled Vegetable ",
            price:12.99
        },
    {
            name:"Stir-fried Fish Fillet ",
            price:12.99
        },
    {
            name:"Fish fillet with XO sauce",
            price:11.99
        },
    {
            name:"Mixed seafood with XO sauce",
            price:13.99
        },
    {
            name:"Two Lobsters with Onion and Ginger",
            price:"Market price"
        },
    {
            name:"Two Lobsters with Black Pepper",
            price:"Market price"
        },
    {
            name:"Two Lobsters with Salt and Pepper ",
            price:"Market price"
        }
    ],
    "Chef's Specialties":[
    {
            name:"Beef with Orange Peel and Chili Peppers*",
            price:12.25
        },
    {
            name:"Happy Family",
            price:12.25
        },
    {
            name:"Hunan Triple Delight*",
            price:12.25
        },
    {
            name:"Mongolian Chicken, Shrimp, and Beef*",
            price:12.25
        },
    {
            name:"Hunan Triple Delight*",
            price:12.25
        },
    {
            name:"Kung Pao Delight*",
            price:12.25
        },
    {
            name:"Sauteed Eggplant in Garlic Sauce (Shrimp, Chicken, Beef, or Pork)*",
            price:12.25
        },
    {
            name:"Salt Pepper Shrimp*",
            price:12.25
        },
    {
            name:"Happy All Seafood Platter",
            price:14.95
        }
    ],
    "Diet Dishes":[
    {
            name:"Steamed Mixed Vegetables ",
            price:8.95
        },
    {
            name:"Steamed Chicken with Broccoli and Snow Peas",
            price:9.25
        },
    {
            name:"Chicken with Chinese Vegetables ",
            price:9.50
        },
    {
            name:"Shrimp with Chinese Vegetables",
            price:10.95
        },
    {
            name:"Shrimp with Snow Peas and Vegetables",
            price:10.95
        },
    {
            name:"Chicken and Shrimp with Snow Peas and Mushroom",
            price:11.95
        },
    {
            name:"Dieter’s Special (Chicken, Shrimp, Scallop)",
            price:11.95
        }
    ],
    "Drinks":[
    {
            name:"Bottle Water",
            price:1.00
        },
    {
            name:"Soda (Can)",
            price:.99
        },
    {
            name:"Soda (Bottle)",
            price:1.50
        },
    {
            name:"Iced Tea (Sweet or Unsweet)",
            price:1.95
        },
    {
            name:"Hot Green Tea ",
            price:1.95
        },
    {
            name:"Juice",
            price:1.95
        }
    ],
    "Lunch Special (11am - 3pm)":[
    {
            name:"General Tso’s Chicken*",
            price:7.75
        },
    {
            name:"Sesame Chicken",
            price:7.75
        },
    {
            name:"Orange Chicken*",
            price:7.75
        },
    {
            name:"Kung Pao Chicken*",
            price:7.45
        },
    {
            name:"Crispy Spicy Salt Chicken*",
            price:7.45
        },
    {
            name:"Moo Goo Gai Pan",
            price:6.95
        },
    {
            name:"Sweet and Sour Chicken",
            price:6.95
        },
    {
            name:"Chicken with Scallion*",
            price:7.45
        },
    {
            name:"Chicken Broccoli",
            price:7.45
        },
    {
            name:"Chicken with Mixed vegetables",
            price:7.45
        },
    {
            name:"Chicken with Cashew Nuts",
            price:7.45
        },
    {
            name:"Hunan Chicken*",
            price:7.45
        },
    {
            name:"Beef with Scallion*",
            price:7.75
        },
    {
            name:"Beef with Chinese Broccoli",
            price:7.75
        },
    {
            name:"Beef Broccoli",
            price:7.75
        },
    {
            name:"Pepper Steak",
            price:7.75
        },
    {
            name:"Spicy Salt Toasted Pork Chop*",
            price:7.45
        },
    {
            name:"Pork Chop with Peking Sauce",
            price:7.45
        },
    {
            name:"Pork with Broccoli",
            price:7.45
        },
    {
            name:"Fish Fillet with Soft Tofu",
            price:7.45
        },
    {
            name:"Sweet and Sour Shrimp",
            price:7.75
        },
    {
            name:"Shrimp with Vegetables",
            price:7.75
        },
    {
            name:"Shrimp with Lobster Sauce",
            price:7.75
        },
    {
            name:"Hunan Shrimp*",
            price:7.75
        },
    {
            name:"Shrimp with Scrambled Eggs",
            price:7.75
        },
    {
            name:"Eggplant with Garlic Sauce**",
            price:7.45
        },
    {
            name:"Mashed Garlic with Vegetable",
            price:7.45
        },
    {
            name:"Sauteed Mixed Vegetables",
            price:7.45
        },
    {
            name:"House Lo Mein",
            price:7.50
        },
    {
            name:"Shrimp Lo Mein",
            price:7.50
        },
    {
            name:"House Fried Rice",
            price:7.75
        },
    {
            name:"Shrimp Fried Rice",
            price:7.75
        },
    
    ],
    
}
    