import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';
const items=require("./menu.js").items

function Header(){
  return( <div className="background">
  <header>
      <div className="hou">Hou</div>
      <div className="houChinese">厚</div>
      <div className="wei">Wei</div>
      <div className="weiChinese">味</div>
  </header>
</div>)
}
function MenuTabs(props:any){
  const [display, setDisplay] = useState(false);
  let productDesc:JSX.Element[]=[];
  if(display){
    let sortedProducts=props.products.slice()
    sortedProducts.sort(function( a:any, b:any ) {
      if ( a.name < b.name ){
        return -1;
      }
      if ( a.name > b.name ){
        return 1;
      }
      return 0;
    } )
    sortedProducts.forEach((product:any)=>{
      productDesc.push(
      (<div className="menuitem">
        <b>{product.name}</b> ${product.price}
        <br/> <p>{product.desc}</p>
      </div>))
    });
  }
  return <><div className="menutab" onClick={()=>{
    setDisplay(!display)
  }}>{props.title}<div className="menuitemwrapper">{productDesc}</div><div className="arrowdown"></div></div>
  </>
}

function Menu(props:any){
  let items:any=Object.keys(props.items).sort().map((item)=>{
    return <MenuTabs title={item} products={props.items[item]}/>
  });
  return (<div className="menu">
    {items}
</div>);
}

function Location(){
  return (
    <div className="location" id="location">
      <h2>Hou Wei</h2>
      <div className="wrapper">
        <div className="hour">
          <b><u>Business Hours</u></b><br/>
          <p>
          <b>Mon. - Thurs: </b> <small>11:00am - 9:00 pm</small><br/><br/>
          <b>Tuesday : </b> <small>Closed</small><br/><br/>
          <b>Fri.- Sat: </b> <small>11:00am - 9:30 pm</small><br/><br/>
          <b>Sunday: </b> <small>11:00am - 9:30 pm</small><br/><br/>
          </p>
          <b><u>Location</u></b><br/>
          <p>
          <b>3645 Cypress Creek Pkwy 218 Houston, TX 77068</b>
          </p>
          <b><u>Phone</u></b><br/>
          <p>
          <b>832 446 3584</b><br/>
          <b>832 446 6072</b>
          </p>
        </div>
        <iframe src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d3455.4067364598964!2d-95.48409818516403!3d29.99647532779263!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e0!4m0!4m5!1s0x8640cc7e5d1b3645%3A0xa2ee94c84322d7da!2sHouwei%20Chinese%20Restaurant%2C%203645%20Cypress%20Creek%20Pkwy%20Suite%20218%2C%20Houston%2C%20TX%2077068!3m2!1d29.9964707!2d-95.4819095!5e0!3m2!1sen!2sus!4v1609005151491!5m2!1sen!2sus " width="600" height="450" frameBorder="0" style={{border:0}} aria-hidden="false"></iframe>
      </div>
    </div>
  );
}

function Footer(){
  return (
    <footer>
      Hou Wei © 2023  
      <br/><small>Site made by <a href="http://tommydong.com">Tom</a></small> 
    </footer>
  );
}

function App() {
  return (
    <div className="App">
      <Header/>
      <Menu items={items}/>
      <Location/>
      <Footer/>
    </div>
  );
}



export default App;